import '../scss/app.scss'; // Importing SCSS file

document.addEventListener('DOMContentLoaded', () => {
    // Get all elements with the class 'key-personnel-text'
    const keyPersonnelItems = document.querySelectorAll('.key-personnel-text');

    // Convert NodeList to an array
    const itemsArray = Array.from(keyPersonnelItems);

    // Function to split full name into [firstName, lastName]
    const splitName = (fullName) => {
        const [firstName, ...rest] = fullName.split(' ');
        const lastName = rest.join(' ');
        return [firstName, lastName];
    };

    // Sort the array by first name, and then by last name if first names are the same
    itemsArray.sort((a, b) => {
        const nameA = splitName(a.querySelector('a').textContent);
        const nameB = splitName(b.querySelector('a').textContent);

        if (nameA[0] === nameB[0]) {
            return nameA[1].localeCompare(nameB[1]);
        }
        return nameA[0].localeCompare(nameB[0]);
    });

    // Get the parent container
    const parentContainer = keyPersonnelItems[0].parentNode;

    // Remove existing items from the parent container
    itemsArray.forEach(item => parentContainer.removeChild(item));

    // Append the sorted items back to the parent container
    itemsArray.forEach(item => parentContainer.appendChild(item));
});
